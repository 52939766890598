.hero-cls-1,
.hero-cls-11,
.hero-cls-12,
.hero-cls-13,
.hero-cls-14,
.hero-cls-15,
.hero-cls-16,
.hero-cls-4,
.hero-cls-5,
.hero-cls-6,
.hero-cls-7,
.hero-cls-8,
.hero-cls-9 {
  fill: none;
}
.hero-cls-2 {
  fill: #f9f9f9;
}
.hero-cls-3 {
  fill: #b2c1b6;
}
.hero-cls-11,
.hero-cls-13,
.hero-cls-15,
.hero-cls-4,
.hero-cls-6,
.hero-cls-8 {
  stroke: #f9f9f9;
}
.hero-cls-4,
.hero-cls-5 {
  stroke-width: 8px;
}
.hero-cls-12,
.hero-cls-14,
.hero-cls-16,
.hero-cls-5,
.hero-cls-7,
.hero-cls-9 {
  stroke: #b2c1b6;
}
.hero-cls-6,
.hero-cls-7 {
  stroke-width: 5px;
}
.hero-cls-8,
.hero-cls-9 {
  stroke-width: 5px;
}
.hero-cls-10 {
  isolation: isolate;
}
.hero-cls-11,
.hero-cls-12 {
  stroke-width: 4px;
}
.hero-cls-13,
.hero-cls-14 {
  stroke-width: 5px;
}
.hero-cls-15,
.hero-cls-16 {
  stroke-miterlimit: 4;
  stroke-width: 5px;
}
