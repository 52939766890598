.about-cls-1 {
  opacity: 0.13;
}
.about-cls-2 {
  fill: #b2c1b6;
}
.about-cls-3 {
  fill: #f9f9f9;
}
.about-cls-10,
.about-cls-4,
.about-cls-5,
.about-cls-6,
.about-cls-7,
.about-cls-8,
.about-cls-9 {
  fill: none;
}
.about-cls-4,
.about-cls-6,
.about-cls-8 {
  stroke: #f9f9f9;
}
.about-cls-4,
.about-cls-5 {
  stroke-width: 5px;
}
.about-cls-5,
.about-cls-7,
.about-cls-9 {
  stroke: #b2c1b6;
}
.about-cls-6,
.about-cls-7 {
  stroke-width: 5px;
}
.about-cls-8,
.about-cls-9 {
  stroke-miterlimit: 4;
  stroke-width: 4px;
}
